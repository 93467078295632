<template>
  <div class="shareface2">
    <div class="title">分享喜悦</div>
    <div class="head">
      <div class="head_son">
        <p>
          {{ $t("shareface[1]") }}<span> STAR MAKING </span
          >{{ $t("shareface[2]") }}
        </p>
        <p>{{ $t("shareface[3]") }}</p>
        <p>
          {{ $t("shareface[4]") }}<span>{{ $t("shareface[5]") }}!</span>
        </p>
      </div>
    </div>
    <div class="btn">
		<button @click="goRegister()">{{ $t("shareface[11]") }}</button>
    </div>
    <div class="two_box">
      <div class="one">
        <div class="fb" @click="share('facebook')"></div>
        <div class="tl" @click="share('twitter')"></div>
      </div>
      <div class="two">
        
      </div>
    </div>
  </div>
</template>
<script>
	export default {
		data() {
			return {
				my_url:{},//地址栏参数
				CurrLine:"",//当前域名
			};
		},
		created() {
			this.CurrLine=localStorage['CurrLine']
			console.log(this.CurrLine)
			this.my_url=this.$route.query
			// console.log(this.$route.query)
		},
		destroyed() {
		},
		methods: {
			// 去注册
			goRegister(){
				window.location.href=`${this.CurrLine}/#/register/${this.my_url.idcode}`
			},
			// 分享到。。。
			share(type) {
				let url_myPage = localStorage['CurrLine'] +`/#/user/promotekid/${this.my_url.idcode}?idcode=${this.my_url.idcode}&urlCode=${this.my_url.urlCode}&money=${this.my_url.money}`
				let url=''
				switch (type) {
					case "facebook":
						url =
							`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url_myPage)}`;
						break
					case "twitter":
						url = `http://twitter.com/share?url=${encodeURIComponent(url_myPage)}`;
						break
					case "linkin":
						url =
							`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=Hello%20World&summary=12312`;
						break
						// case "instagram":
						//     url = `https://www.instagram.com/aiaindonesia/?hl=en`;
						//     break
					case "whatsapp":
						url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}&url=${encodeURIComponent(shareUrl)}`
						break
					case "email":
						url = `mailto:?subject=Life%20Insurance%20Advisor%20-%20AIA%20Indonesia&body=${encodeURIComponent(shareUrl)}`
						break
					case "messenger":
						url = `fb-messenger://share/?link=${encodeURIComponent(shareUrl)}`
						break
					case "viber":
						url = `viber://forward?text=${encodeURIComponent(shareUrl)}`
						break
					case "message":
						var u = navigator.userAgent;
						//判断是否为android终端
						var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
						//判断是否为ios终端
						var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
						//获取要分享的端口号
						var tel = ''
						//这里填写要分享的内容
						var des = encodeURIComponent(shareUrl);
						//ios终端
						if (isiOS) {
							url = "sms:" + tel + "&body=" + des;
							//安卓终端
						} else {
							url = "sms:" + tel + "?body=" + des;
						}
						break
				}
				console.log(url)
				window.open(url,'_blank')
			},
		},
	};
</script>
<style scoped>
.shareface2 {
  background: url("../../../public/static/images/promote_bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.title {
  height: 50px;
  font-size: 18px;
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 50px;
}
span {
  color: #fe889d;
  font-size: 16px;
  font-weight: 600;
}

.head {
  text-align: center;
  width: 320px;
  height: 250px;
  margin: auto;
  position: relative;
  top: 100px;
  z-index: 9;
  background: url("../../../public/static/images/title2.png") no-repeat;
  background-size: 100% 100%;
}
.head_son {
  position: absolute;
  top: 35px;
  left: 25px;
  color: #4e51bf;
  line-height: 20px;
  font-size: 21px;
  font-weight: 600;
  line-height: 27px;
}
.head_son p:nth-of-type(2) {
  font-size: 17px;
}
.head_son span {
  font-size: 22px;
}
.head_son p:nth-of-type(3) {
  font-size: 17px;
  margin-left: 20px;
}
.head_son p:nth-of-type(3) span {
  font-size: 17px;
}
.two_box {
  margin: 0 20px 0 20px;
  margin-top: 280px;
}
.one {
  display: flex;
  justify-content: center;
}
.two {
  display: flex;
  justify-content: center;
}
.one div {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background-color: #fff;
  margin: 2%;
}
.two div {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  margin: 2%;
}
.ytb {
  background: url("../../../public/static/icon2/youtube.png") no-repeat;
  background-size: 30px;
  background-position: 10px;
}
.wa {
  background: url("../../../public/static/icon2/wa.png") no-repeat;
  background-size: 30px;
  background-position: 10px;
}
.fb {
  background: url("../../../public/static/icon2/facebook.png") no-repeat;
  background-size: 30px;
  background-position: 10px;
}
.wx {
  background: url("../../../public/static/icon2/wx.png") no-repeat;
  background-size: 30px;
  background-position: 10px;
}
.qq {
  background: url("../../../public/static/icon2/qq.png") no-repeat;
  background-size: 30px;
  background-position: 10px;
}
.tl {
  background: url("../../../public/static/icon2/telegram.png") no-repeat;
  background-size: 30px;
  background-position: 10px;
}
.null {
}
.more {
  color: #4e51bf;
  font-weight: 600;
}
.btn {
  width: 100%;
  position: absolute;
  top: 400px;
  text-align: center;
}
.btn button {
  width: 180px;
  height: 45px;
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to right, #fee9cf, #b3b5f8);
  color: #4e51bf;
  font-size: 18px;
  font-weight: 600;
}
</style>